import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  useEffect(() => {
    // const datosGuardados = localStorage.getItem('tokenUser');
    // const tokenJson = JSON.parse(datosGuardados);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    // const body = JSON.stringify(tokenJson);
    fetch('/sinproteger', {
      method: 'POST',
      headers,
      // body,
    }).then((res) => {
      console.log(res.message)
      console.log(res)
      console.log(res.json())
      return res.json();
    })
      .then((data) => {
        // if (data?.token) {
        //   localStorage.setItem('tokenUser', JSON.stringify(data?.token));
        // }
      })
      .catch((err) => {
        console.log(err)
      });

  }, [])


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
